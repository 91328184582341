import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
  Stack,
  Button,
  Modal,
  CircularProgress,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { GetAllProductAction } from "../../redux/action/GetProductAction";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { UpdateProductAction } from "../../redux/action/UpdateProductAction";
import { AddProductAction } from "../../redux/action/AddProductAction";
import Snackbar from "@mui/material/Snackbar";
import { useAuthContext } from "../../layouts/dashboard/AuthContext";
import { useStyles } from "./commonCss.js";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Iconify from "../../components/iconify";
import { UploadImagesAction } from "../../redux/action/UploadImagesAction.js";
import heic2any from "heic2any";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};

export default function ProductModal(props) {
  const dispatch = useDispatch();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const GetAllProduct = (search, selectedProduct, pageNumber) =>
    dispatch(GetAllProductAction(search, selectedProduct, pageNumber));

  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(
    props?.data?.image || null
  );
  const [titleError, setTitleError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [markupPriceError, setMarkupPriceError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [originsError, setOriginsError] = useState("");
  const [missionsError, setMissionsError] = useState("");
  const [roastError, setRoastError] = useState("");
  const [beanError, setBeanError] = useState("");
  const [imageError, setImageError] = useState("");
  const [files, setFiles] = useState(props?.data?.image || []);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [beanValue, setBeanValue] = useState("");
  const [beanArray, setBeanArray] = useState([]);
  const [priceType, setPriceType] = useState("Flat");
  const [imageLoading, setImageLoading] = useState(false);
  const [originalProduct, setOriginalProduct] = useState(null);

  const [product, setProduct] = useState({
    id: props?.data?._id || "",
    title: props?.data?.title || "",
    price: props?.data?.price || "",
    markup_price: props?.data?.markup_price || "0",
    markup_type: props?.data?.markup_type || "Flat",
    // category: props?.data?.category?.id || "",
    description: props?.data?.description || "",
    image: props?.data?.image || "",
    weight: props?.data?.weight || "",
    origins: props?.data?.origins || "",
    roast: props?.data?.roast || "",
    bean: props?.data?.bean || "",
    missions: props?.data?.missions || "",
  });

  const beanData = [
    {
      id: 1,
      title: "Whole Bean",
    },
    {
      id: 2,
      title: "Ground",
    },
    {
      id: 3,
      title: "Special",
    },
    {
      id: 4,
      title: "Pods",
    },
  ];
  useEffect(() => {
    if (props.data) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        id: props.data._id,
        title: props.data.title,
        price: props.data.price,
        markup_price: props?.data?.markup_price || "0",
        markup_type: props?.data?.markup_type || "Flat",
        description: props.data.description,
        image: files,
        weight: props.data.weight,
        origins: props.data.origins,
        roast: props.data.roast,
        bean: props.data.bean,
        missions: props.data.missions,
      }));
      setBeanArray([...props?.data?.bean]);
      setFiles(props?.data?.image || []);
      setPriceType(props?.data?.markup_type ?? "Flat");
    }
  }, [props.data]);
  // Add or Edit Product

  useEffect(() => {
    if (beanArray.length) {
      setBeanError("");
    }
  }, [beanArray]);

  // remove Image
  const handleRemoveImage = (index) => {
    const tempPostImage = [...files];
    tempPostImage.splice(index, 1);
    setFiles(() => [...tempPostImage]);
  };

  const handelSubmit = async () => {
    if (!product.title) setTitleError("* Title is required");
    if (!product.price) setPriceError("* Price is required");
    if (!product.markup_price)
      setMarkupPriceError("* Markup Price is required");
    // if (!product.category) setCategoryError("* Category is required");
    if (!product.description) setDescriptionError("* Description is required");
    if (!product.weight) setWeightError("* Weight is required");
    if (!product.origins) setOriginsError("* Origins is required");
    if (!product.missions) setMissionsError("* Missions is required");
    if (!product.roast) setRoastError("* Roast is required");
    if (!product?.bean) setBeanError("* Bean is required");
    if (!product.image && files.length === 0) {
      setImageError("* Image is required");
      return;
    }

    if (
      !product.title ||
      !product.price ||
      !product.markup_price === "" ||
      // !product.category ||
      !product.description ||
      !product.weight ||
      !product.origins ||
      !product.roast ||
      !product.missions ||
      !product?.bean ||
      // !beanArray.length ||
      (!product.image && files.length === 0 && !uploadedImage)
    ) {
      return;
      if (!product.image && files.length === 0) {
        setImageError("* Image is required");
        return;
      }
    }
    if (!files.length) {
      setImageError("* Image is required");
      return;
    }

    setLoading(true);

    if (props.isUser) {
      try {
        const payload = {
          product_id: product?.id,
          title: product?.title,
          description: product?.description,
          price: Number(product?.price),
          markup_price:
            product.markup_price === "" ? 0 : Number(product.markup_price),
          markup_type: priceType ?? "Flat",
          weight: product?.weight,
          origins: product?.origins,
          missions: product?.missions,
          roast: product?.roast,
          bean: product?.bean,
          image: files,
        };

        const response = await UpdateProductAction(payload);
        if (response.isSuccess) {
          setSuccessOpen(true);
          props.handleCloseModal();
          setProduct({
            id: "",
            title: "",
            price: "",
            markup_price: "",
            markup_type: priceType,
            // category: "",
            description: "",
            image: "",
            weight: "",
            origins: "",
            roast: "",
            bean: "",
            missions: "",
          });
          setFiles([]);
          setBeanArray([]);
          setBeanValue("");
          GetAllProduct(
            props.search || "",
            props.selectedProduct,
            props.page + 1
          );
        } else {
          setErrorOpen(true);
          setErrorMsg(response.data.message);
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const payload = {
          title: product?.title,
          description: product?.description,
          price: Number(product?.price),
          markup_price: product?.markup_price,
          markup_type: priceType ?? "Flat",
          weight: product?.weight,
          origins: product?.origins,
          missions: product?.missions,
          roast: product?.roast,
          bean: product?.bean,
          image: files,
        };
        const res = await AddProductAction(payload);
        if (res?.isSuccess) {
          setSuccessOpen(true);
          props.handleCloseModal();
          setProduct({
            id: "",
            title: "",
            price: "",
            // category: "",
            description: "",
            image: "",
            weight: "",
            origins: "",
            missions: "",
            roast: "",
            bean: "",
          });
          setBeanArray([]);
          setBeanValue("");
          setFiles([]);
          GetAllProduct(props.search || "", "", props.page + 1);
        } else {
          setErrorOpen(true);
          setErrorMsg(res.message || "Failed to add product.");
        }
      } catch (error) {
        console.error("API call failed:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.isUser && product) {
      setOriginalProduct({ ...product });
      setProduct({ ...product });
    }
  }, [props.isUser]);

  const isProductChanged = () => {
    if (!originalProduct) return true;
    const originalFiles = originalProduct.image || [];
    const currentFiles = files;
    return (
      JSON.stringify(product) !== JSON.stringify(originalProduct) ||
      JSON.stringify(originalFiles) !== JSON.stringify(currentFiles)
    );
  };

  // add or Edit product success close
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setErrorOpen(false);
    props.handleCloseModal();
  };

  // add or Edit product error close
  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleFormData = useCallback(
    (e) => {
      setProduct({ ...product, [e.target.name]: e.target.value });
      if (e.target.name === "title") setTitleError("");
      if (e.target.name === "price") setPriceError("");
      if (e.target.name === "markup_price") setMarkupPriceError("");
      if (e.target.name === "description") setDescriptionError("");
      // if (e.target.name === "category") setCategoryError("");
      if (e.target.name === "image") setImageError("");
      if (e.target.name === "weight") setWeightError("");
      if (e.target.name === "missions") setMissionsError("");
      if (e.target.name === "origins") setOriginsError("");
      if (e.target.name === "roast") setRoastError("");
      if (e.target.nam === "bean") setBeanError("");
    },
    [product]
  );
  // Select Image
  const handleSelectImage = async (e) => {
    setImageLoading(true);
    const formData = new FormData();
    const selectedFilesArray = Array.from(e.target.files);
  
    for (let image of selectedFilesArray) {
      if (image.type === "image/heic" || image.name.endsWith(".heic")) {
        try {
          const convertedBlob = await heic2any({ blob: image, toType: "image/jpeg" });
          const convertedFile = new File([convertedBlob], image.name.replace(".heic", ".jpg"), { type: "image/jpeg" });
          formData.append("image", convertedFile);
        } catch (error) {
          console.error("HEIC to JPEG conversion error:", error);
          setImageLoading(false);
          return;
        }
      } else {
        formData.append("image", image);
      }
    }
  
    try {
      const response = await UploadImagesAction(formData);
      if (response.isSuccess) {
        setFiles((prevFiles) => [...prevFiles, ...response.data.image]);
        setImageError("");
      } else {
        setImageLoading(false);
      }
    } catch (error) {
      console.error("Upload Error:", error);
      setImageError("There was an error during the upload. Please try again.");
    } finally {
      setImageLoading(false);
    }
  };
  
  const modalTitle = props.isUser ? "Edit Product" : "Add Product";
  const buttonText = props.isUser ? "Update" : "Add Product";

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
    const cleanValue = inputValue
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    setProduct((prevProduct) => ({
      ...prevProduct,
      price: cleanValue,
    }));
  };

  const handleMarkupPrice = (e) => {
    const inputValue = e.target.value;
    const cleanValue = inputValue
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    setProduct((prevProduct) => ({
      ...prevProduct,
      markup_price: cleanValue,
    }));
  };

  const AllOrigins = [
    {
      value: "Blend",
      name: "Blend",
    },
    {
      value: "Honduras_Ethiopia",
      name: "Honduras & Ethiopia",
    },
    {
      value: "Peru",
      name: "Peru",
    },
    {
      value: "Colombia",
      name: "Colombia",
    },
    {
      value: "Guatemala_Ethiopia",
      name: "Guatemala & Ethiopia",
    },
    {
      value: "Latin America_Ethiopia",
      name: "Latin America & Ethiopia",
    },
    {
      value: "Ethiopia",
      name: "Ethiopia",
    },
    {
      value: "Ethiopia_Colombia",
      name: "Ethiopia & Colombia",
    },
    {
      value: "Guatemala",
      name: "Guatemala",
    },
    {
      value: "Costa Rica",
      name: "Costa Rica",
    },
    {
      value: "Special",
      name: "Special",
    },
    {
      value: "Brazil",
      name: "Brazil",
    },
    {
      value: "Malawi",
      name: "Malawi",
    },
    {
      value: "Sumatra",
      name: "Sumatra",
    },
    {
      value: "Honduras",
      name: "Honduras",
    },
    {
      value: "Mexico",
      name: "Mexico",
    },
    {
      value: "Indonesia",
      name: "Indonesia",
    },
    {
      value: "Kenya",
      name: "Kenya",
    },
    {
      value: "Nicaragua",
      name: "Nicaragua",
    },
  ];

  const AllMissions = [
    {
      value: "End Human Trafficking",
      name: "End Human Trafficking",
    },
    {
      value: "Latin America Evangelism and Mission",
      name: "Latin America Evangelism and Mission",
    },
    {
      value: "Economic Development_Education",
      name: "Economic Development & Education",
    },
    {
      value: "Supporting Wounded Veterans",
      name: "Supporting Wounded Veterans",
    },
    {
      value: "Funding Good in the World",
      name: "Funding Good in the World",
    },
    {
      value: "Food Insecurity",
      name: "Food Insecurity",
    },
    {
      value: "Restore survivors of sex trafficking",
      name: "Restore survivors of sex trafficking",
    },
    {
      value: "Doing Good With Coffee",
      name: "Doing Good With Coffee",
    },
  ];

  const AllRoast = [
    {
      value: "Medium",
      name: "Medium",
    },
    {
      value: "Espresso",
      name: "Espresso",
    },
    {
      value: "Light Roast",
      name: "Light Roast",
    },
    {
      value: "Medium Roast",
      name: "Medium Roast",
    },
    {
      value: "Espresso_Dark",
      name: "Espresso & Dark",
    },
    {
      value: "Dark Roast",
      name: "Dark Roast",
    },
    {
      value: "Special",
      name: "Special",
    },
    {
      value: "Medium/Dark",
      name: "Medium/Dark",
    },
    {
      value: "Medium/Light",
      name: "Medium/Light",
    },
    {
      value: "Expresso/Medium",
      name: "Expresso/Medium",
    },
    {
      value: "Decaf",
      name: "Decaf",
    },
    {
      value: "Pods",
      name: "Pods",
    },
  ];

  const classes = useStyles();

  const handleChange = (event) => {
    setPriceType(event.target.value ?? "Flat");
  };
  return (
    <>
      <div>
        <Snackbar
          open={isSuccessOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" onClose={handleSuccessClose}>
            Product updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" onClose={handleErrorClose}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
      <Box>
        <Modal
          className="model_height dropdown"
          open={props.productModal}
          onClose={props.handleCloseModal}
        >
          <Box sx={style} className="product-scroll">
            <Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h5">{modalTitle}</Typography>
                </Stack>
              </Grid>
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="title"
                        id="title"
                        label={
                          <span>
                            Title <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={product.title}
                      />
                      <div className="invalid-msg mt-2">{titleError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="weight"
                        id="weight"
                        label={
                          <span>
                            Weight <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={product.weight}
                      />
                      <div className="invalid-msg mt-2">{weightError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="price"
                        id="price"
                        label={
                          <span>
                            Price <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        inputMode="text"
                        onChange={handlePriceChange}
                        value={product.price === "" ? "" : `$${product.price}`}
                      />
                      <div className="invalid-msg mt-2">{priceError}</div>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      {/* <TextField
                        fullWidth
                        name="origins"
                        id="origins"
                        label={
                          <span>
                            Origins <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={product.origins}
                      /> */}
                      <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InputLabel id="origins">
                          Origins <span className={classes.red}>*</span>
                        </InputLabel>
                        <Select
                          value={product.origins}
                          labelId="origins"
                          label="Origins"
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "origins",
                                value: e.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {AllOrigins?.map((code) => {
                            return (
                              <MenuItem
                                key={code?.value}
                                value={code?.value}
                                style={{ fontSize: "14px" }}
                              >
                                {code?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div className="invalid-msg mt-2">{originsError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      {/* <TextField
                        fullWidth
                        name="roast"
                        id="roast"
                        label={
                          <span>
                            Roast <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={product.roast}
                      /> */}
                      <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InputLabel id="roast">
                          Roast <span className={classes.red}>*</span>
                        </InputLabel>
                        <Select
                          value={product.roast}
                          labelId="roast"
                          label="Roast"
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "roast",
                                value: e.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {AllRoast?.map((code) => {
                            return (
                              <MenuItem
                                key={code?.value}
                                value={code?.value}
                                style={{ fontSize: "14px" }}
                              >
                                {code?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div className="invalid-msg mt-2">{roastError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InputLabel id="missions">
                          Missions <span className={classes.red}>*</span>
                        </InputLabel>
                        <Select
                          value={product.missions}
                          labelId="missions"
                          label="Missions"
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "missions",
                                value: e.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {AllMissions?.map((code) => {
                            return (
                              <MenuItem
                                key={code?.value}
                                value={code?.value}
                                style={{ fontSize: "14px" }}
                              >
                                {code?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div className="invalid-msg mt-2">{missionsError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className="markup_price_button"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      <FormLabel id="markup-radio-buttons-group-label">
                        Markup Price
                      </FormLabel>
                      <RadioGroup
                        value={priceType}
                        onChange={handleChange}
                        aria-labelledby="markup-radio-buttons-group-label"
                        defaultValue="Flat"
                        name="radio-buttons-group"
                        fullWidth
                      >
                        <FormControlLabel
                          value="Flat"
                          control={<Radio />}
                          label="Flat"
                        />
                        <FormControlLabel
                          value="Percentage"
                          control={<Radio />}
                          label="Percentage"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="markup_price"
                        id="markup_price"
                        label={
                          <span>
                            {priceType} Price{" "}
                            <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        inputMode="numeric"
                        onChange={handleMarkupPrice}
                        value={product.markup_price}
                      />
                      <div className="invalid-msg mt-2">{markupPriceError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        my: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="description"
                        id="description"
                        label={
                          <span>
                            Description <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={product.description}
                      />
                      <div className="invalid-msg mt-2">{descriptionError}</div>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Select
                          value={product.category}
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "category",
                                value: e.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="" style={{ fontSize: "14px" }}>
                            Category (Missions)
                            <span className={classes.red}>*</span>
                          </MenuItem>
                          {getAllCategoriesState?.result?.map((code) => {
                            return (
                              <MenuItem
                                key={code?._id}
                                value={code?._id}
                                style={{ fontSize: "14px" }}
                              >
                                {code?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div className="invalid-msg mt-2">{categoryError}</div>
                    </Box>
                  </Grid> */}

                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 2,
                      }}
                    >
                      {/* <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InputLabel id="bean">Bean</InputLabel>
                        <Select
                          value={product?.bean}
                          labelId="bean"
                          label="Bean"
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "bean",
                                value: e.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {beanData?.map((code) => {
                            return (
                              <MenuItem
                                key={code?.title}
                                value={code?.title}
                                style={{ fontSize: "14px" }}
                              >
                                {code?.title}
                              </MenuItem>
                            );
                          })}
                         
                        </Select>
                      </FormControl> */}

                      <FormControl
                        sx={{
                          width: 380,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InputLabel id="bean">
                          Bean <span className={classes.red}>*</span>
                        </InputLabel>
                        <Select
                          value={product?.bean || []}
                          labelId="bean"
                          label="Bean"
                          multiple
                          onChange={(e) => {
                            handleFormData({
                              target: {
                                name: "bean",
                                value: e.target.value,
                              },
                            });
                          }}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                width: 250,
                              },
                            },
                          }}
                        >
                          {beanData?.map((code) => (
                            <MenuItem key={code?.title} value={code?.title}>
                              <Checkbox
                                checked={
                                  product?.bean?.indexOf(code?.title) > -1
                                }
                              />
                              <ListItemText
                                primary={code?.title}
                                style={{ fontSize: "14px" }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <div className="invalid-msg mt-2">{beanError}</div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box className={classes.mT}>
                      <Button
                        variant="contained"
                        component="label"
                        className={classes.imageSlt}
                      >
                        <AddPhotoAlternateIcon
                          sx={{ fontSize: 40, marginBottom: "10px" }}
                        />
                        Click to Select Image
                        <input
                          name="image"
                          type="file"
                          hidden
                          accept=".png, .jpg, .jpeg, .heic"
                          onChange={handleSelectImage}
                          multiple
                        />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className={classes.mT}>
                    <div className="grid-3">
                      {files?.map((url, index) => {
                        return (
                          <div className="img_remove">
                            <img
                              key={index}
                              src={`${imageURL}${url}`}
                              alt={`Uploaded Image ${index}`}
                              crossOrigin="anonymous"
                              className={classes.img}
                            />
                            <div className="remove_icon">
                              <Iconify
                                width={16}
                                icon={"material-symbols-light:close"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveImage(index);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <div className="img-load">
                        {imageLoading && (
                          <CircularProgress
                            strokeColor="#fff"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible
                          />
                        )}
                      </div>
                    </div>
                    <div className="invalid-msg mt-2">{imageError}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      marginTop: "50px",
                    }}
                  >
                    <Button
                      onClick={props.handleCloseModal}
                      type="submit"
                      className={classes.clsBtn}
                      sx={{ py: 2 }}
                      variant="contained"
                    >
                      <p className={classes.prpl}>Close</p>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      marginTop: "50px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        strokeColor="#fff"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible
                      />
                    ) : (
                      <Button
                        onClick={handelSubmit}
                        type="submit"
                        className={classes.sbtBtn}
                        sx={{ py: 2 }}
                        variant="contained"
                        disabled={!isProductChanged()}
                      >
                        {buttonText}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
